.blog-detail-container {
    width: 80%;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6em;
    color: #333;
    background-color: #f4f4f4;
    border-radius: 5px;
    box-shadow: 0px 0px 8px #bbb;
}

.blog-detail-container h1 {
    text-align: center;
    letter-spacing: 1px;
}

.blog-detail-container p {
    text-indent: 30px;
    margin: 10px 0;
}

.blog-not-found {
    text-align: center;
    color: red;
}
