.hero {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #292b2c;
    color: #fff;
}

.hero h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.hero h2 {
    font-size: 1.5em;
    margin-bottom: 40px;
}

.hero button {
    padding: 10px 20px;
    font-size: 1em;
    color: #292b2c;
    background-color: #fff;
    border: none;
    cursor: pointer;
}

.about,
.services,
.contact-teaser {
    padding: 50px;
    text-align: center;
}

.about h2,
.services h2,
.contact-teaser h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.about p,
.services p {
    font-size: 1.2em;
    max-width: 800px;
    margin: auto;
    line-height: 1.5;
}


.contact-teaser button {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #292b2c;
    border: none;
    cursor: pointer;
    text-align: center;
}