.services-page {
    padding: 2rem;
    font-family: Arial, sans-serif;
}

.services-header {
    text-align: center;
    margin-bottom: 3rem;
}

.services-header h1 {
    font-size: 2.5rem;
    color: #333;
}

.services-header p {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 1rem auto;
}

.service-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.service-item {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.service-item h3 {
    font-size: 1.5rem;
    color: #444;
}

.service-item p {
    font-size: 1rem;
    color: #777;
}
