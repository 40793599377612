.merch-store {
    padding: 2rem;
    font-family: Arial, sans-serif;
}

.products {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.product {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.product img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

.product h3 {
    font-size: 1.5rem;
    margin: 1rem 0;
}

.product p {
    font-size: 1rem;
    margin-bottom: 1rem;
}

button {
    background-color: #333;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #555;
}

/* ... existing styles */

.carousel {
    position: relative;
}

.carousel img {
    max-width: 100%;
    transition: opacity 0.5s;
}

.carousel-controls {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.carousel-controls .prev,
.carousel-controls .next {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
    margin: -10px;
    font-size: 20px;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.carousel-controls .prev:hover,
.carousel-controls .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.carousel-indicators {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
}

.carousel-indicators span {
    width: 10px;
    height: 10px;
    background-color: #ddd;
    border-radius: 50%;
    margin: 0 5px;
    transition: background-color 0.3s;
}

.carousel-indicators span.active {
    background-color: #555;
}

/* media query for tablet view */
@media (max-width: 1024px) {
    .products {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* media query for mobile view */
@media (max-width: 768px) {
    .products {
        grid-template-columns: 1fr;
    }
}