footer {
    width: 100%;
    padding: 20px;
    text-align: center;
    background-color: #292b2c;
    color: #fff;
}

.footer-text {
    font-size: 0.875em;
}

.footer-links {
    list-style: none;
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.footer-links li {
    margin-left: 20px;
}

.footer-links li a {
    color: #fff;
    text-decoration: none;
}