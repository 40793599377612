.container {
    margin-top: 50px;
  }
  
  form {
    margin-bottom: 30px;
  }
  
  .btn-primary {
    background-color: #021254; /* Change button color */
  }