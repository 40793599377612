.blog-list-container {
    width: 80%;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6em;
    color: #333;
}

.blog-list-item {
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 8px #bbb;
}

.blog-list-item h2 {
    margin-bottom: 10px;
    text-align: left;
}

.blog-list-item p {
    margin-bottom: 20px;
}

.blog-list-item a {
    color: #337ab7;
    text-decoration: none;
    border: 1px solid #337ab7;
    padding: 5px;
    border-radius: 5px;
}

.blog-list-item a:hover {
    background-color: #337ab7;
    color: white;
}