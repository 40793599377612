.container {
    max-width: 1150px;
    margin: auto;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #292b2c;
}

.navbar .logo a {
    font-size: 1.5em;
    color: #fff;
    text-decoration: none;
}

.navbar ul {
    list-style: none;
    display: flex;
}

.navbar ul li {
    margin-left: 20px;
}

.navbar ul li a {
    color: #fff;
    text-decoration: none;
}


.navbar .logo {
    display: flex;
    align-items: center;
}

.navbar .logo-img {
    width: 50px;          /* You may adjust this */
    height: auto;
    margin-right: 10px;    /* You may adjust this */
}