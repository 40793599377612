* {
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background: #F3F3F3;
}

.About {
    max-width: 800px;
    margin: auto;
    background: #FFFFFF;
    padding: 20px;
}

.About h1 {
    font-size: 2em;
    color: #434343;
    text-transform: uppercase;
    margin-top: 60px;
    text-align: center;
    letter-spacing: 2px;
}

.About h2 {
    color: #434343;
    text-transform: uppercase;
    margin-top: 20px;
    text-align: left;
    letter-spacing: 1px;
}

.About p {
    line-height: 26px;
    font-size: 18px;
    padding: 10px 0;
    color: #787878;
    font-family: 'Poppins', sans-serif;
}

.About section {
    border-bottom: 1px solid #DADCDD;
    padding-bottom: 20px;
}

.About button {
    color: #fff;
    border-radius: 50px;
    background: #FF586C;
    text-align: center;
    border: none;
    padding: 15px 40px;
    cursor: pointer;
    transition: all .3s ease;
    font-size: 16px;
}
    
.About button:hover {
    background-color: #434343;
}

.About img {
    width: 100%;
}